import { ValidateRoute } from "../ValidRoute";
import OccupancyAgreementsContainer from "../../OccupancyAgreement/OccupancyAgreementsContainer.js";
import OccupancyAgreements from "../../OccupancyAgreement/OccupancyAgreements.js";
import CreateOccupancyAgreement from "../../OccupancyAgreement/CreateOccupancyAgreement.js";
import OccupancyAgreementDetails from "../../OccupancyAgreement/OccupancyAgreementDetails.js";
export const ROUTE_OCCUPANCY_AGREEMENTS = {
    plain: 'OccupancyAgreements',
    withSlash: '/OccupancyAgreements',
    title: 'Occupancy Agreements',
    module: 'ROUTE_OCCUPANCY_AGREEMENTS',
};
export const ROUTE_EDIT_OCCUPANCY_AGREEMENTS = {
    plain: ':occupancyAgreementId',
    withSlash: '/:occupancyAgreementId',
    title: 'Edit an Occupancy Agreement',
    module: 'ROUTE_OCCUPANCY_AGREEMENTS',
    edit: true,
};
export const ROUTE_CREATE_OCCUPANCY_AGREEMENTS = {
    plain: 'Create',
    withSlash: '/Create',
    title: 'Create an Occupancy Agreement',
    module: 'ROUTE_OCCUPANCY_AGREEMENTS',
    create: true,

};
// obsolete...
export const ROUTE_OCCUPANCY_AGREEMENTS_LIST = {
    plain: ':id/list',
    withSlash: '/:id/list',
    title: 'Occupancy Agreements',
    module: 'ROUTE_OCCUPANCY_AGREEMENTS'
};
export const occupancyAgreementRoutes = [
    {
        path: ROUTE_OCCUPANCY_AGREEMENTS.plain,
        element: <OccupancyAgreementsContainer />,
        children: [
            {
                index: true,
                element: <ValidateRoute route={ROUTE_OCCUPANCY_AGREEMENTS}><OccupancyAgreements /></ValidateRoute>,
            },
            {
                path: ROUTE_CREATE_OCCUPANCY_AGREEMENTS.plain,
                element: <ValidateRoute route={ROUTE_CREATE_OCCUPANCY_AGREEMENTS}><CreateOccupancyAgreement /></ValidateRoute>
            },
            {
                path: ROUTE_EDIT_OCCUPANCY_AGREEMENTS.plain,
                element: <ValidateRoute route={ROUTE_EDIT_OCCUPANCY_AGREEMENTS}><OccupancyAgreementDetails /></ValidateRoute>

            },

        ]
    },

];
