import {
    useEffect,
    useRef,
    useState
} from "react";
import { ValidationGroup } from "devextreme-react";
import { ToolbarForm } from "../components/toolbar-form/toolbar-form";
import OccupancyFormDetails from "./OccupancyFormDetails";
import PutPostPatchFetch from "../hooks/PutPostPatchFetch";
import ClosingAlert from "../components/ClosingAlert";
import { useNavigate } from "react-router-dom";

const OccupancyForm = ({ data, isLoading = false, create = false }) => {
    // console.log("Data at OccupancyForm.:", data);
    const [editing, setEditing] = useState(create);
    const [formData, setFormData] = useState(data);
    const navigate = useNavigate();
    const dataRef = useRef(null);
    const validationGroupRef = useRef(null);
    const navigateToRoute = () => navigate(-1);
    const [occupancyAgreementSaved, setOccupancyAgreementSaved] = useState(false);
    const [occupancyAgreementSaveFail, setOccupancyAgreementSaveFail] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const endpoint = '/v1/OccupancyAgreement';
    const saveMethod = create ? 'POST' : 'PUT';
    useEffect(() => {
        setFormData(data);
        if (create) {
            dataRef.current = formData;
        }
    }, [data]);
    const handleEditClick = () => {
        if (!editing && formData) {
            dataRef.current = formData;
        }
        else {
            dataRef.current = undefined;
        }
        setEditing(!editing);
    };
    const onSaveClick = async () => {
        const { isValid, brokenRules } = validationGroupRef.current.instance.validate();
        if (brokenRules) {
            console.warn(`${brokenRules.length} rules have failed due to user input.`)
        }
        if (!isValid) {
            return;
        }
        setOccupancyAgreementSaveFail(false);
        setOccupancyAgreementSaved(false);
        setErrorMessage("");
        const { Success, Message, Errors } = await PutPostPatchFetch(endpoint, saveMethod, formData);
        if (Success) {
            // method successful but may still return "false" where endpoint wasn't able to save/add record
            if (Message) {
                setOccupancyAgreementSaved(true);
                setOccupancyAgreementSaveFail(false);
                setErrorMessage("");
            }
            else {
                setErrorMessage("We were unable to save this information. Please try again or contact your system's administrator");
                setOccupancyAgreementSaved(false);
                setOccupancyAgreementSaveFail(true);
            }
        }
        else {
            // 400s to 500s status codes
            console.log("What went wrong:", Errors);
            setErrorMessage(Errors[0].Message);
            setOccupancyAgreementSaved(false);
            setOccupancyAgreementSaveFail(true);
        }
        setEditing(false);
    };

    const onCancelClick = () => {
        setFormData(dataRef.current);
        handleEditClick();
    };
    const onBackClick = () => {
        navigate(-1);
    }
    const updateField = (field, value) => {
        if (!formData) return;
    
        // Split the field by dot to get all the nested levels
        const fieldParts = field.split(".");
    
        // Function to traverse and update the nested fields
        const updateNestedField = (obj, fieldParts, value) => {
            const currentField = fieldParts[0]; // Get the first part of the field path
            if (fieldParts.length === 1) {
                // If there's no more depth, we update the value
                obj[currentField] = value;
            } else {
                // If there are more levels, recurse into the nested object
                if (!obj[currentField]) {
                    obj[currentField] = {}; // Ensure the object exists
                }
                updateNestedField(obj[currentField], fieldParts.slice(1), value); // Recurse deeper
            }
        };
    
        // Start the update process
        const updatedFormData = { ...formData }; // Clone formData to avoid mutation
    
        // Call the recursive function to update the specific field
        updateNestedField(updatedFormData, fieldParts, value);
    
        // Update the state with the new formData
        setFormData(updatedFormData);
    };
    return (
        <>
            <div className='occupancy-agreement-form'>
                <ValidationGroup ref={validationGroupRef}>
                    <ToolbarForm
                        toggleEditing={handleEditClick}
                        onSaveClick={onSaveClick}
                        editing={editing}
                        onCancelClick={onCancelClick}
                        onBackClick={onBackClick}
                    />
                    <OccupancyFormDetails
                        loading={isLoading}
                        hasData={!formData}
                        data={formData}
                        editing={editing}
                        updateField={updateField}
                    />
                </ValidationGroup>
            </div>
            <ClosingAlert
                clickableIcon={navigateToRoute}
                enableAlertIcon={true}
                message={`Occupancy Agreement was saved successfully. Click the "Forward" icon to be routed back to Occupancy Agreement Page.`}
                severity="success"
                visible={occupancyAgreementSaved}
            />
            <ClosingAlert
                enableAlertIcon={true}
                clickableIcon={() => { setOccupancyAgreementSaveFail(false) }}
                message={errorMessage}
                severity="warning"
                visible={occupancyAgreementSaveFail}
            />
        </>
    )
}

export default OccupancyForm;