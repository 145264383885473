import { BaseSelectField } from "../components/BaseSelectField/BaseSelectField";
import { useEffect, useState } from "react";
import GetFetch from "../hooks/GetFetch";
import DataSource from "devextreme/data/data_source";
import ArrayStore from "devextreme/data/array_store";
import ODataStore from "devextreme/data/odata/store";
import SingleRegionSelectField from "../BuildingsPage/components/SingleRegionSelectField/SingleRegionSelectField";

// const testData = [
//     { BuildingId: 15007, SequenceNumber: '12345', RegionNumber: 1 },
//     { BuildingId: 158, SequenceNumber: '1234VA', RegionNumber: 1 },
//     { BuildingId: 196, SequenceNumber: '1297AL', RegionNumber: 1 },
//     { BuildingId: 293, SequenceNumber: '1299ZZ', RegionNumber: 1 },
//     { BuildingId: 160, SequenceNumber: '12A67V', RegionNumber: 1 },
//     { BuildingId: 161, SequenceNumber: '3M5D67', RegionNumber: 1 },
//     { BuildingId: 15011, SequenceNumber: '4321QA', RegionNumber: 1 },
//     { BuildingId: 217, SequenceNumber: '4343FG', RegionNumber: 1 },
//     { BuildingId: 216, SequenceNumber: '4343FK', RegionNumber: 1 },
//     { BuildingId: 15009, SequenceNumber: '54321', RegionNumber: 1 },
//     { BuildingId: 187, SequenceNumber: '5496VA', RegionNumber: 1 },
//     { BuildingId: 159, SequenceNumber: '5678A', RegionNumber: 1 },
//     { BuildingId: 245, SequenceNumber: '5948VA', RegionNumber: 1 },
//     { BuildingId: 242, SequenceNumber: '7571VA', RegionNumber: 1 },
//     { BuildingId: 188, SequenceNumber: '8954VA', RegionNumber: 1 },
//     { BuildingId: 251, SequenceNumber: 'AA0000', RegionNumber: 1 },
//     { BuildingId: 101, SequenceNumber: 'AA1112', RegionNumber: 1 },
//     { BuildingId: 208, SequenceNumber: 'AL9564', RegionNumber: 1 },
//     { BuildingId: 239, SequenceNumber: 'AM0001', RegionNumber: 1 },
//     { BuildingId: 290, SequenceNumber: 'AV1234', RegionNumber: 1 },
//     { BuildingId: 150, SequenceNumber: 'BB1010', RegionNumber: 1 },
//     { BuildingId: 103, SequenceNumber: 'BB1023', RegionNumber: 1 },
//     { BuildingId: 209, SequenceNumber: 'BE7843', RegionNumber: 1 },
//     { BuildingId: 109, SequenceNumber: 'CC1001', RegionNumber: 1 },
//     { BuildingId: 106, SequenceNumber: 'CC2111', RegionNumber: 1 },
//     { BuildingId: 240, SequenceNumber: 'CM0001', RegionNumber: 1 },
//     { BuildingId: 305, SequenceNumber: 'CT0001', RegionNumber: 1 },
//     { BuildingId: 306, SequenceNumber: 'CT0002', RegionNumber: 1 },
//     { BuildingId: 307, SequenceNumber: 'CT0004', RegionNumber: 1 },
//     { BuildingId: 308, SequenceNumber: 'CT0005', RegionNumber: 1 },
// ]
const CustomerBuildingSelect = ({ value, valueSetter }) => {
    const [buildingList, setBuildingList] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedRegion, setSelectedRegion] = useState(null);
    const [error, setError] = useState(null);
    useEffect(() => {
        fetchBuildings();
    }, [selectedRegion]);

    const fetchBuildings = async () => {
        if (selectedRegion) {
            setIsLoading(true);
            const { Success, Message, Errors } = await GetFetch(`/v1/Building/Region/` + selectedRegion);
            if (Success) {
                setBuildingList(Message);
                console.log("buildings message", Message)
            }
            else {
                setError(Errors[0].Message);
            }
            setIsLoading(false);
        }
    }
    const optionUpdatedByUser = (e) => {
        // console.log(e)
        const sel = e.selectedItem;
        valueSetter(sel)
    };
    const regionUpdatedByUser = (e) => {
        // console.log(e)
        const sel = e.selectedItem;
        setSelectedRegion(sel)
    };
    // const buildingsStore = new ODataStore({
    //     data: fetchBuildings(),
    //     key: 'BuildingId',
    //     // onLoaded: function() {
    //     //     // ...
    //     // }
    // });
    // const selectBoxData = new DataSource({
    //     store: buildingsStore,
    //     paginate: true,
    //     pageSize: 100
    // });  
    const buildingDataSource = new DataSource({
            store: {
                type: 'array',
                data: buildingList,
                key: 'BuildingId'
            },
            paginate: true,
            pageSize: 10
        });
    return (
        <div style={{ display: 'flex' }}>
            <SingleRegionSelectField
                label="Select a Region"
                optionUpdatedByUser={regionUpdatedByUser}
                isVisible={true}
                value={selectedRegion}
            />
            {isLoading ? <>loading...</>
                :
                <BaseSelectField
                    inputAttribute={{ id: "customer_building_select_dropdown" }}
                    label="Select a Building"
                    data={buildingDataSource}
                    enableSearch={true}
                    optionUpdatedByUser={optionUpdatedByUser}
                    value={value}
                    optionsLabel={"SequenceNumber"}
                    requireValidation={false}
                    isVisible={true}
                    readOnly={selectedRegion==null}
                />}

        </div>
    )
}
export default CustomerBuildingSelect;