import { useState } from "react";
import { GetDateToday_dashes_reverse } from "../util/GetDateToday";
import { useAuth } from "../hooks/useAuth";
import OccupancyForm from "./OccupancyForm";

const CreateOccupancyAgreement = () => {
    const { user } = useAuth();
    const [data, setData] = useState({
        "OccupancyAgreementId": 0,
        "BuildingByCustomerId": 0,
        "CustomerAccountId": undefined,
        "BuildingId": undefined,
        "EffectiveDate": "",
        "ExpirationDate": "",
        "CreatedDate": GetDateToday_dashes_reverse(),
        "CreatedBy": user.email,
        "UpdatedDate": null,
        "UpdatedBy": "",
        "OANumber": "",
        "LeaseNumber": "",
        "MemberName": "",
        "TotalRentSqFt": 0,
        "JURentSqFt": 0,
        "RUFactor": 0,
        "Inactive": false,
    });
    const [isLoading, setIsLoading] = useState(false);
    // const [error, setError] = useState(null);

    return (
        <>
            <h4>Add an Occupancy Agreement</h4>
            <OccupancyForm
                data={data}
                isLoading={isLoading}
                create={true}
            />
        </>
    )
}
export default CreateOccupancyAgreement;