import React from 'react';
import NumberBox from 'devextreme-react/number-box';
import { Button as NumberBoxButton } from 'devextreme-react/button';
import './FormNumberBox.scss';

/**
 * @typedef {object} NumberBoxProps
 * @property {number} value - The current value of the number box
 * @property {string?} label - The label to display for the field
 * @property {boolean} isEditing - Flag to indicate whether the field is in edit mode
 * @property {function(data): void} onValueChange - Callback to handle value changes
 * @property {string?} icon - Optional icon to display before the input field
 * @property {boolean} readOnly - Flag to make the input read-only
 * @property {string?} format - Optional format for the number
 * @property {number} min - The maximum allowed number
 * @property {number} max - The minimum allowed number
 * @property {number} step 
 */

/**
 * @param {NumberBoxProps} data
 */

export const FormNumberBox = ({
  value,
  label,
  isEditing,
  onValueChange,
  icon,
  readOnly = false,
  format, // default to 'fixedPoint' format
  min,
  max,
  step,
  children
}) => {
  const isReadOnly = (readOnly, isEditing) => {
    if (readOnly) {
      return readOnly;
    }
    return !isEditing; // if not editing, make it read-only
  };

  return (
    <NumberBox
      label={label}
      value={value}
      min={min}
      max={max}
      format={format}  // You can pass custom format like 'fixedPoint' or 'percent'
      readOnly={isReadOnly(readOnly, isEditing)}
      elementAttr={{ class: 'form-editor' }}
      inputAttr={{ class: 'form-editor-input' }}
      stylingMode='filled'
      onValueChange={onValueChange}
      step={step}
    >
      {icon && (
        <NumberBoxButton
          name="icon"
          location="before"
          options={{
            icon: icon,
            stylingMode: 'text',
            elementAttr: { class: 'form-editor-icon' },
          }}
        />
      )}
      {/* Optional: Add validation logic if needed */}
      {/* <Validator>
        <RequiredRule />
        {children}
      </Validator> */}
    </NumberBox>
  );
};