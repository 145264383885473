import React, { useEffect, useState } from 'react';
import { useAuth } from '../hooks/useAuth';
import { BaseButton } from '../components/BaseButton/BaseButton';
import { useNavigate } from 'react-router-dom';
import { ROUTE_OCCUPANCY_AGREEMENTS } from '../routes/OccupancyAgreement/Routes';
import OccupancyAgreementsTable from './OccupancyAgreementsTable';

const OccupancyAgreements = () => {
  const [forceReload, setForceReload] = useState(false);
  const { user } = useAuth();
  const [allowEditing, setAllowEditing] = useState(false);
  const [allowCreate, setAllowCreate] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    const permissions = user.role.ModulePermissions?.filter(module => { return (module.Page === ROUTE_OCCUPANCY_AGREEMENTS.module) })
    setAllowEditing(permissions?.filter(module => { return (module.Update) }).length > 0)
    setAllowCreate(permissions?.filter(module => { return (module.Create) }).length > 0)
  }, [])

  const handleAdd = () => {
    navigate('Create');
  }
  return (
    <div>
      
      {allowCreate && <BaseButton
        label="Create an Occupancy Agreement"
        onClick={handleAdd}
        variant="outlined"
      />}

      {/* {allowEditing && <BaseButton
          ariaDisabled={isRecordSelected}
          disabled={isRecordSelected}
          label={editCustomerButtonLabel}
          onClick={handleEditButtonClick}
          variant={outlined}
        />} */}
      <OccupancyAgreementsTable forceReload={forceReload} stopForceReload={() => { setForceReload(false) }} allowEditing={allowEditing} allowCreate={allowCreate} />
      <br />
    </div>
  );
}

export default OccupancyAgreements;
