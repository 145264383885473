import React, { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { ROUTE_OCCUPANCY_AGREEMENTS } from '../routes/OccupancyAgreement/Routes';

const OccupancyAgreementsContainer = () => {
  let pageTitle = ROUTE_OCCUPANCY_AGREEMENTS.title;

  useEffect(() => {
    const title = ROUTE_OCCUPANCY_AGREEMENTS.title;
    document.title = title;
  }, []);
  return (
    <>
      <h1>{pageTitle}</h1>
      <Outlet />
    </>

  );
}

export default OccupancyAgreementsContainer;
