import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { LoadingIndicatorCircle } from "../components/Loading";
import GetFetch from "../hooks/GetFetch";
import OccupancyForm from "./OccupancyForm";

const UpdateOccupancyAgreement = () => {
    const { occupancyAgreementId } = useParams();
    const [data, setData] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        fetchOccupancyAgreement(occupancyAgreementId);
    }, []);

    const fetchOccupancyAgreement = async (id) => {
        setIsLoading(true);
        const { Success, Message, Errors } = await GetFetch(`/v1/OccupancyAgreement/${id}`);

        if (Success) {
            setData(Message);
            console.log("Message", Message)
        }
        else {
            setError(Errors[0].Message);
        }
        setIsLoading(false);
    }

    return (
        <>
            {/* <h4>Updating Occupancy Agreement with ID: [{occupancyAgreementId}]</h4> */}
            {isLoading && <LoadingIndicatorCircle message={`Loading Occupancy Agreement ${occupancyAgreementId}...`} />}
            {data &&
                <>
                    {/* <div>{JSON.stringify(data)}</div> */}
                    <OccupancyForm
                        data={data}
                        isLoading={isLoading}
                    />
                </>
            }

        </>
    )
}

export default UpdateOccupancyAgreement;