import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ROUTE_CRMA_DOWNLOAD, ROUTE_WELCOME } from "../../routes/Routes";
import { BaseButton } from "../../components/BaseButton/BaseButton";
import { BaseDateField } from "../../components/BaseDateField/BaseDateField";
import GetFetchDownload from "../../hooks/GetFetchDownload";
import "./CRMA_Download.scss";
import { BaseSelectField } from "../../components/BaseSelectField/BaseSelectField";
import { DateBox } from "devextreme-react";

const CRMA_DownloadPage = () => {
    const [_billingDate, set_BillingDate] = useState();
    const [files, setFiles] = useState();
    const [error, setError] = useState(false);
    const [data, setData] = useState();
    const [errorMessage, setErrorMessage] = useState();

    const { title } = ROUTE_CRMA_DOWNLOAD;
    useEffect(() => { document.title = title }, []);
    useEffect(() => { fetchCRMAdata() }, [])

    const navigate = useNavigate();
    const navigateToRoute = () => navigate(ROUTE_WELCOME.withSlash);

    const fetchCRMAdata = async () => {
        if (_billingDate) {
            const billDate = '07-04-2024'
            const endpoint = '/v1/CRMADownload/GetCRMASpreadsheets?billingDate=' + _billingDate;
            const contentType = 'zip';

            console.log("CRMA_Download: _billingDate", _billingDate);
            try {
                const result = await GetFetchDownload(_billingDate);
                console.log("CRMA_Download: result", result)

            } catch (errors) {
                console.error(errors);
            }
        }
    };
    return (
        <>
            <h1> {title} </h1>
            <div className="block block__div--wrapper">
                <DateBox
                    dateSerializationFormat="MM/dd/yyyy"
                    useMaskBehavior={true}
                    displayFormat="monthAndYear"
                    inputAttr={{
                        "aria-label": "Billing Date", 
                        "aria-required": "true"
                    }}
                    label="Billing Date"
                    onValueChanged={(e) => set_BillingDate(e.value)}
                    showDropDownButton={false}
                    type="date"
                    value={_billingDate ?? null}
                    variant={"outlined"}
                />
            </div>

            <div className="block block__div--wrapper">
                <BaseButton
                    label={"Download File(s)"}
                    onClick={fetchCRMAdata}
                    variant={"outlined"}
                />
                <BaseButton
                    label={"Cancel"}
                    onClick={navigateToRoute}
                    variant={"outlined"}
                />
            </div>
        </>
    );
};

export default CRMA_DownloadPage;

