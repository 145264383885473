import {
    useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import GetFetch from '../hooks/GetFetch';
import CustomStore from 'devextreme/data/custom_store';
import DataGrid, {
    KeyboardNavigation,
    Pager,
    Paging,
    SearchPanel,
    Column,
    Export,
    Scrolling
} from 'devextreme-react/data-grid';
import CustomPreparedHeaderCell from '../components/CustomPreparedHeaderCell';
import { logExport, useAuth } from '../hooks/useAuth';
import ExportGrid from '../components/ExportGrid';
import { allowedPageSizes, pageFormats } from "../components/GlobalDataGridConfigurations";
import ClosingAlert from '../components/ClosingAlert';
import './css/Customers.scss';
import { BaseButton } from '../components/BaseButton/BaseButton';

const CustomersTable = () => {
    const navigate = useNavigate();
    const { user } = useAuth();
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState();

    const fetchCustomers = () => {
        return GetFetch('/v1/Customer/GetCustomers')
    };

    const dataSource = new CustomStore({
        key: 'CustomerAccountId',
        load: async () => {
            const data = await fetchCustomers();
            const { Message } = data;
            console.log(data);
            let checkedData = [];
            if (Message)
                checkedData = Message.map(
                    (
                        {
                            CustomerAccountId,
                            AgencyName,
                            AgencyBureauName,
                            AgencyBureauShortName,
                            AgencyBureauCode,
                            BilledAgencyBureauCode,
                            ActualAgencyBureauCode,
                            AgencyLocationCode,
                            TreasuryAccSymbol,
                            BusinessEventTypeCode,
                            CustomerPOCFirstName,
                            CustomerPOCLastName,
                            POCsToRecieveSummary,
                            SendIPAC,
                            FundingDocumentNumber,
                            BureauAddress: {
                                Id,
                                Address1,
                                Address2,
                                CityName,
                                State,
                                ZipCode
                            },
                            CreatedBy,
                            CreatedDate,
                        }
                    ) => {

                        return {
                            CustomerAccountId: CustomerAccountId,
                            AgencyName: AgencyName,
                            AgencyBureauName: AgencyBureauName,
                            AgencyBureauShortName: AgencyBureauShortName,
                            AgencyBureauCode: AgencyBureauCode,
                            BilledAgencyBureauCode: BilledAgencyBureauCode,
                            ActualAgencyBureauCode: ActualAgencyBureauCode,
                            AgencyLocationCode: AgencyLocationCode,
                            TreasuryAccSymbol: TreasuryAccSymbol,
                            BusinessEventTypeCode: BusinessEventTypeCode,
                            POCFullName: CustomerPOCFirstName + " " + CustomerPOCLastName,
                            CustomerPOCFirstName: CustomerPOCFirstName,
                            CustomerPOCLastName: CustomerPOCLastName,
                            POCsToRecieveSummary: POCsToRecieveSummary === true ? "Yes" : "No",
                            PocsToReceiveSummaryActual: POCsToRecieveSummary,
                            SendIPAC: SendIPAC === true ? "Yes" : "No",
                            SendIpacActual: SendIPAC,
                            FundingDocumentNumber: FundingDocumentNumber,
                            BureauAddressId: Id,
                            address: `${Address1}, ${Address2 !== null && Address2 !== '' ? Address2 + ',' : ''} ${CityName}, ${State} ${ZipCode}`,
                            Address1: Address1,
                            Address2: Address2,
                            CityName: CityName,
                            StateLocation: State,
                            ZipCode: ZipCode,
                            CreatedBy: CreatedBy,
                            CreatedDate: CreatedDate,
                        };
                    }
                );

            return checkedData;
        }
    });

    const pdfFont = () => { return 7; }
    const wrapPdfCell = () => {
        return true;
    }
    const handleExport = (e) => {
        logExport(user.email);
        ExportGrid(e, "Customers_Data", wrapPdfCell(), pdfFont())
    }
    const renderEditCell = ({ data }) => {
        // console.log(data)
        return (
            <BaseButton
                variant='outlined'
                ariaLabel={`edit-button-customer-with-id-${data.CustomerAccountId}`}
                id={`edit-button-customer-with-id-${data.CustomerAccountId}`}
                label="See Details/Edit"
                noStyle={true}
                // onClick={() => navigate(`${ROUTE_EDIT_CUSTOMER.useNav}${data.CustomerAccountId}`)}
                onClick={() => navigate(`${data.CustomerAccountId}`)}
            />
        )
    }

    return (
        <div>
            <ClosingAlert
                severity="error"
                message={errorMessage}
                visible={error}
            />

            <div>
                {
                    !error &&
                    <span
                        aria-live="polite"
                        aria-atomic="true"
                    >
                        <DataGrid
                            columnAutoWidth={true}
                            dataSource={dataSource}
                            onCellPrepared={CustomPreparedHeaderCell}
                            showBorders={true}
                            showColumnLines={true}
                            showRowLines={true}
                            rowAlternationEnabled={true}
                            onExporting={e => handleExport(e)}
                        >
                            <KeyboardNavigation enabled={true} />
                            <SearchPanel visible={true} highlightCaseSensitive={true} />
                            <Scrolling />
                            <Paging defaultPageSize={10} />
                            <Pager
                                showPageSizeSelector={true}
                                allowedPageSizes={allowedPageSizes}
                            />
                            <Column cellRender={renderEditCell} />
                            <Column dataField="CustomerAccountId" visible={false} />
                            <Column dataField="BureauAddressId" visible={false} />
                            <Column dataField="AgencyName" caption="Agency Name" />
                            <Column dataField="AgencyBureauName" caption="Agency Bureau Name" />
                            <Column dataField="AgencyBureauShortName" caption="AB Short Name" />
                            <Column dataField="AgencyBureauCode" caption="AB Code" />
                            <Column dataField="BilledAgencyBureauCode" caption="Billed AB Code" />
                            <Column dataField="ActualAgencyBureauCode" caption="Actual AB Code" />
                            <Column dataField="AgencyLocationCode" caption="Agency Location Code" />
                            <Column dataField="address" caption="Address" />
                            <Column dataField="Address1" caption="Address1" visible={false} />
                            <Column dataField="Address2" caption="Address2" visible={false} />
                            <Column dataField="CityName" caption="CityName" visible={false} />
                            <Column dataField="StateLocation" caption="State" visible={false} />
                            <Column dataField="ZipCode" caption="ZipCode" visible={false} />
                            <Column dataField="TreasuryAccSymbol" caption="Treasury Acc. Symbol" />
                            <Column dataField="BusinessEventTypeCode" caption="Business Event Type Code" />
                            <Column dataField="POCFullName" caption="Customer POC" />
                            <Column dataField="CustomerPOCFirstName" caption="CustomerPOCFirstName" visible={false} />
                            <Column dataField="CustomerPOCLastName" caption="CustomerPOCLastName" visible={false} />
                            <Column dataField="POCsToRecieveSummary" caption="POC to Receive Summary" />
                            <Column dataField="PocsToReceiveSummaryActual" caption="actual PocSum Value" visible={false} />
                            <Column dataField="SendIPAC" caption="Send IPAC" />
                            <Column dataField="SendIpacActual" caption="actual SendIpacActual Value" visible={false} />
                            <Column dataField="FundingDocumentNumber" caption="Funding Doc #" />
                            <Column dataField="CreatedBy" caption="CreatedBy" visible={false} />
                            <Column dataField="CreatedDate" caption="CreatedDate" visible={false} />
                            <Export
                                enabled={true}
                                formats={pageFormats}
                            />
                        </DataGrid>
                    </span>
                }
            </div>
        </div >
    );
};


export default CustomersTable;