import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { logExport, useAuth } from "../hooks/useAuth";
import { DataGrid } from "devextreme-react";
import CustomPreparedHeaderCell from "../components/CustomPreparedHeaderCell";
import GetFetch from "../hooks/GetFetch";
import { Column, Export, KeyboardNavigation, Pager, Paging, Scrolling, SearchPanel } from "devextreme-react/cjs/data-grid";
import { allowedPageSizes, pageFormats } from "../components/GlobalDataGridConfigurations";
import ExportGrid from "../components/ExportGrid";
import CustomerBuildingSelect from "./CustomerBuildingSelect";
import { BaseButton } from "../components/BaseButton/BaseButton";
import PutPostPatchFetch from "../hooks/PutPostPatchFetch";
import { GetDateToday_dashes_reverse } from "../util/GetDateToday";

const CustomerBuildings = () => {
    const { customerId } = useParams();
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedBuilding, setSelectedBuilding] = useState(null);
    const [error, setError] = useState(null);
    const { user } = useAuth();
    useEffect(() => {
        fetchCustomerBuildings(customerId);
    }, []);
    const fetchCustomerBuildings = async (id) => {
        setIsLoading(true);
        const { Success, Message, Errors } = await GetFetch(`/v1/Building/Customer/${id}`);
        if (Success) {
            setData(Message);
            console.log("Message", Message)
        }
        else {
            setError(Errors[0].Message);
        }
        setIsLoading(false);
    }
    const pdfFont = () => { return 7; }
    const wrapPdfCell = () => {
        return true;
    }
    const handleExport = (e) => {
        logExport(user.email);
        ExportGrid(e, `Customer_RMS_Sys_ID_${customerId}_Building_Data`, wrapPdfCell(), pdfFont())
    }
    const handleAddBuilding = async () => {
        if (!selectedBuilding) {
            return;
        }
        // Check if the selectedBuilding already exists in the data array based on BuildingId
        const isDuplicate = data.some(building => building.BuildingId === selectedBuilding.BuildingId);

        if (isDuplicate) {
            return; // If it's a duplicate, do nothing
        }
        let dataCopy = JSON.parse(JSON.stringify(data))
        dataCopy.push(selectedBuilding);
        setData(dataCopy);
        const buildByCust = {
            "BuildingByCustomerId": 0,
            "CustomerId": customerId,
            "BuildingId": selectedBuilding.BuildingId,
            "IsInactive": false,
            "CreatedDate": GetDateToday_dashes_reverse(),
            "CreatedBy": user.email,
            "UpdatedDate": null,
            "UpdatedBy": ""
        };
        setIsLoading(true);
        const { Success, Message, Errors } = await PutPostPatchFetch('/v1/BuildingByCustomer/AddBuildingByCustomerRecords', 'POST', buildByCust);
        setIsLoading(false);
        console.log("Success", Success);
        console.log("Message", Message);
        console.log("Errors", Errors);

    }
    return (
        <>
            {isLoading ?
            <div>
                    <span>Loading...</span>
                </div>
                :
                <>
                    <div>
                <span><strong>Customer Buildings</strong></span>
            </div>
            {data &&
                <span
                    aria-live="polite"
                    aria-atomic="true"
                >
                            <CustomerBuildingSelect value={selectedBuilding} valueSetter={setSelectedBuilding} />
                            <BaseButton
                                label="Add Building to Customer"
                                variant="contained"
                                disabled={!selectedBuilding}
                                onClick={handleAddBuilding}
                            />
                    <DataGrid
                                // columnAutoWidth={true}
                        dataSource={data}
                        onCellPrepared={CustomPreparedHeaderCell}
                        showBorders={true}
                        showColumnLines={true}
                        showRowLines={true}
                        rowAlternationEnabled={true}
                        onExporting={e => handleExport(e)}
                    >
                        <KeyboardNavigation enabled={true} />
                        <SearchPanel visible={true} highlightCaseSensitive={true} />
                        <Scrolling />
                        <Paging defaultPageSize={10} />
                        <Pager
                            showPageSizeSelector={true}
                            allowedPageSizes={allowedPageSizes}
                        />
                                <Column dataField="BuildingId" visible={false} />
                        <Column dataField="SequenceNumber" caption="Building Number" />
                        <Column dataField="Name" caption="Name" />
                        <Column dataField="RegionNumber" caption="Region Number" />
                                {/* <Column dataField="Name" caption="Name" />
                        <Column dataField="AdjustedRentSqFt" caption="Adjusted Rent Sq Ft" />
                        <Column dataField="AdjustedUsageSqFt" caption="Adjusted Usage Sq Ft" />
                        <Column dataField="AnsiRentSqFt" caption="Ansi Rent Sq Ft" />
                        <Column dataField="AnsiUsableSqFt" caption="Ansi Usable SqFt" />
                        <Column dataField="CommunityJointUseSqFt" caption="Community Joint Use SqFt" />
                        <Column dataField="CongressionalRep" caption="Congressional Representative" />
                        <Column dataField="Facility" caption="Facility" />
                        <Column dataField="address" caption="Address" />
                        <Column dataField="Address1" caption="Address1" visible={false} />
                        <Column dataField="Address2" caption="Address2" visible={false} />
                        <Column dataField="CityName" caption="CityName" visible={false} />
                        <Column dataField="StateLocation" caption="State" visible={false} />
                        <Column dataField="ZipCode" caption="ZipCode" visible={false} />
                        <Column dataField="Joint Use Sq Ft" caption="JointUseSqFt" />
                        <Column dataField="LeaseJointUseSqFt" caption="Lease Joint Use SqFt" />
                        
                        <Column dataField="RentUsageFactor" caption="Rent Usage Factor" />
                                <Column dataField="Status" caption="Status" /> */}
                        <Export
                            enabled={true}
                            formats={pageFormats}
                        />
                    </DataGrid>
                        </span>
                    }
                </>
            }
        </>
    )
}
export default CustomerBuildings;