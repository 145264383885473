import CreateCustomer from "../../CustomerAccounts/CreateCustomer";
import Customers from "../../CustomerAccounts/Customers";
import CustomersContainer from "../../CustomerAccounts/CustomersContainer";
import { ValidateRoute } from "../ValidRoute";
import CustomerDetails from "../../CustomerAccounts/CustomerDetails.js";

// Customer Routes
export const ROUTE_CUSTOMERS = {
    plain: 'Customers',
    withSlash: '/Customers',
    title: 'Customers',
    module: 'ROUTE_CUSTOMERS'
};
export const ROUTE_CREATE_CUSTOMER = {
    plain: 'Create',
    withSlash: '/Create',
    title: 'Add a Customer',
    module: 'ROUTE_CUSTOMERS',
    create: true
};
export const ROUTE_EDIT_CUSTOMER = {
    plain: ':customerId',
    // useNav: 'Edit/',
    withSlash: '/:customerId',
    title: 'Edit a Customer',
    module: 'ROUTE_CUSTOMERS',
    edit: true
};
export const customerRoutes = [
    {
        path: ROUTE_CUSTOMERS.plain,
        element: <CustomersContainer />,
        children: [
            {
                index: true,
                element: <ValidateRoute route={ROUTE_CUSTOMERS}><Customers /></ValidateRoute>,
            },
            {
                path: 'Create',
                element: <ValidateRoute route={ROUTE_CREATE_CUSTOMER}><CreateCustomer /></ValidateRoute>
            },
            {
                path: ROUTE_EDIT_CUSTOMER.plain,
                element: <ValidateRoute route={ROUTE_EDIT_CUSTOMER}><CustomerDetails /></ValidateRoute>

            },
        ]
    },

];
