import {
    useEffect,
    useState,
    useCallback,
    useRef
} from 'react';
import classNames from 'classnames'
import Form, {
    Item as FormItem,
    GroupItem,
    ColCountByScreen,
    PatternRule,
    StringLengthRule
} from 'devextreme-react/form';
import {
    Building,
    BuildingByCustomer,
    Customer,
    Location,
    OccupancyAgreement
} from '../types/occupancyAgreement';
import { Endpoints } from '../Enums/endpoints';
import GetFetch from '../hooks/GetFetch';
import { FormTextBox } from '../components/FormTextBox/FormTextBox';
import { FormDateBox } from '../components/FormDateBox/FormDateBox';
import { FormSelectBox } from '../components/FormSelectBox/FormSelectBox';
import { FormNumberBox } from '../components/FormNumberBox/FormNumberBox';
import DataSource from 'devextreme/data/data_source';

const {
    GET_BUILDING_BY_CUSTOMER_BY_CUSTOMER_ID
} = Endpoints;


/** @typedef {import('../types/occupancyAgreement').Building} Building */
/** @typedef {import('../types/occupancyAgreement').BuildingByCustomer} BuildingByCustomer */
/** @typedef {import('../types/occupancyAgreement').Customer} Customer */
/** @typedef {import('../types/occupancyAgreement').Location} Location */
/** @typedef {import('../types/occupancyAgreement').OccupancyAgreement} OccupancyAgreement */

/**
 * @param {OccupancyAgreement} data
 * @param {boolean} editing
 * @param {function(field, value): void} updateField
 */

const OccupancyFormDetails = ({ data, editing, updateField }) => {
    const [customers, setCustomers] = useState([]);
    const [buildings, setBuildings] = useState([]);
    const [loadingBuildings, setLoadingBuildings] = useState(false);
    const [loadingCustomers, setLoadingCustomers] = useState(false);

    useEffect(() => {
        setLoadingCustomers(true);
        fetchAndSet('/v1/Customer/GetCustomers', setCustomers)
        setLoadingCustomers(false);
    }, []);
    useEffect(() => {
        if (data.CustomerAccountId) {
            // updateField('BuildingByCustomer.Building', null);
            setLoadingBuildings(true);
            fetchAndSet(`/v1/Building/Customer/${data.CustomerAccountId}`, setBuildings);
            setLoadingBuildings(false);
        }
    }, [data.CustomerAccountId]);

    const onFieldDataChanged = useCallback((e) => {
        if (e.dataField === "CustomerAccountId") {
            setLoadingBuildings(true);
            fetchAndSet(`/v1/Building/Customer/${data.CustomerAccountId}`, setBuildings);
            setLoadingBuildings(false);
        }
    }, [])

    const fetchAndSet = async (URL, setter) => {
        const { Errors, Message, Success } = await GetFetch(URL);
        if (Success === true) {
            setter(Message);
            console.log(`URL call: [${URL}]`, Message);
        }
        else {
            console.error(Errors[0].Message);
        }
    }

    const customerDataSource = new DataSource({
        store: {
            type: 'array',
            data: customers,
            key: 'CustomerId'
        },
        paginate: true,
        pageSize: 10
    });
    const buildingByCustomerDataSource = new DataSource({
        store: {
            type: 'array',
            data: buildings,
            key: 'BuildingId'
        },
        paginate: true,
        pageSize: 10
    });
    //#region Utility Methods
    const formInputToUppercase = (field, value) => {
        updateField(field, value.toUpperCase());
    }
    //#endregion Utility Methods
    return (
        <Form className={classNames({ 'plain-styled-form': true, 'view-mode': !editing })}
            labelMode='floating' onFieldDataChanged={onFieldDataChanged}>
            <GroupItem colCount={4} caption='General Information' >
                <ColCountByScreen xs={4} />
                <FormItem>
                    <FormTextBox
                        label='OA Number'
                        value={data.OANumber}
                        isEditing={editing}
                        maxLength={8}
                        required={true}
                        onValueChange={e => formInputToUppercase('OANumber', e)}
                    >
                        <PatternRule
                            pattern="^[A-Z0-9]+$"
                            message="Letters and digits allowed."
                        />
                        <StringLengthRule
                            max={8}
                            message="Can't be more than 8 characters."
                        />
                    </FormTextBox>
                </FormItem>
                <FormItem>
                    <FormDateBox
                        label='Effective Date'
                        value={data.EffectiveDate}
                        isEditing={editing}
                        onValueChange={e => updateField('EffectiveDate', e)}
                        required={true}
                    />
                </FormItem>
                <FormItem cssClass='accent'>
                    <FormDateBox
                        label='Expiration Date'
                        value={data.ExpirationDate}
                        isEditing={editing}
                        required={true}
                        onValueChange={e => updateField('ExpirationDate', e)}
                    />
                </FormItem>
                <FormItem>
                    <FormTextBox
                        label='Member Name'
                        value={data.MemberName}
                        isEditing={editing}
                        onValueChange={e => updateField('MemberName', e)}
                        maxLength={20}
                    >
                        <PatternRule
                            pattern="^[a-zA-Z0-9 ]+$"
                            message="No special characters are allowed, except spaces."
                        />

                    </FormTextBox>
                </FormItem>
                <FormItem dataField='CustomerAccountId'>
                    <FormSelectBox
                        label='Customer'
                        value={data.CustomerAccountId}
                        isEditing={editing}
                        dataSource={customerDataSource}
                        readOnly={loadingCustomers}
                        displayExpr={"AgencyName"}     // Display SequenceNumber as the label
                        valueExpr={"CustomerAccountId"}
                        onValueChange={e => updateField('CustomerAccountId', e)}
                        required={true}
                    />

                </FormItem>
                <FormItem dataField='BuildingId'>
                    <FormSelectBox
                        label='Building Number'
                        value={data.BuildingId}
                        isEditing={editing}
                        dataSource={buildingByCustomerDataSource}
                        readOnly={loadingBuildings || (data.CustomerAccountId === null || data.CustomerAccountId === undefined || data.CustomerAccountId === 0)}
                        displayExpr={"SequenceNumber"}     // Display SequenceNumber as the label
                        valueExpr={"BuildingId"}
                        onValueChange={e => updateField('BuildingId', e)}
                        required={true}
                    />

                </FormItem>
            </GroupItem>
            <GroupItem colCount={4} caption='Billing Information'>
                <ColCountByScreen xs={4} />
                <FormItem>
                    <FormNumberBox
                        label='Joint Use Rent'
                        value={data.JURentSqFt}
                        isEditing={editing}
                        onValueChange={e => updateField('JURentSqFt', e)}
                        format="#,##0.00 SQ FT"
                        min={0} // Minimum for NUMERIC(18,2)
                        max={9999999999999999.99}  // Maximum for NUMERIC(18,2)
                    />
                </FormItem>
                <FormItem>
                    <FormTextBox
                        label='Lease Number'
                        value={data.LeaseNumber}
                        isEditing={editing}
                        onValueChange={e => formInputToUppercase('LeaseNumber', e)}
                        maxLength={8}
                    >
                        <PatternRule
                            pattern="^[a-zA-Z0-9]+$"
                            message="No special characters are allowed."
                        />
                    </FormTextBox>
                </FormItem>
                <FormItem>
                    <FormNumberBox
                        label='Rent Usage Factor'
                        value={data.RUFactor}
                        isEditing={editing}
                        onValueChange={e => updateField('RUFactor', e)}
                        min={0.001}          // Minimum allowed value
                        max={0.999}          // Maximum allowed value
                        step={0.001}         // Step size, so it increments by 0.001
                        format="0.###"
                        showSpinButtons={true}
                    />
                </FormItem>
                <FormItem>
                    <FormNumberBox
                        label='Total Rent'
                        value={data.TotalRentSqFt}
                        isEditing={editing}
                        onValueChange={e => updateField('TotalRentSqFt', e)}
                        format="#,##0.00 SQ FT"
                        min={0} // Minimum for NUMERIC(18,2)
                        max={9999999999999999.99}  // Maximum for NUMERIC(18,2)
                    />
                </FormItem>

            </GroupItem>
        </Form>
    );
};

export default OccupancyFormDetails;