import React from 'react';

import TextBox, { Button as TextBoxButton } from 'devextreme-react/text-box';
import Validator, { RequiredRule } from 'devextreme-react/validator';
import './FormTextBox.scss';

/* type TextBoxProps = {
  value: string,
  label?: string,
  isEditing: boolean,
  onValueChange: (data) => void,
  mask?: string,
  icon?: string,
  maxLength?: number,
  required: boolean
} */

/**
 * @typedef {object} TextBoxProps
 * @property {string} value
 * @property {string?} label
 * @property {boolean} isEditing
 * @property {function(data): void} onValueChange
 * @property {string?} mask
 * @property {string?} icon
 * @property {number?} maxLength
 * @property {boolean} required
 */

/**
 * @param {TextBoxProps} data
 */

export const FormTextBox = ({
  value,
  label,
  isEditing,
  onValueChange,
  mask = '',
  icon,
  required = false,
  maxLength,
  children
}) => {
  return (
    <TextBox
      label={label}
      value={value}
      mask={mask}
      readOnly={!isEditing}
      elementAttr={{ class: 'form-editor' }}
      inputAttr={{ class: 'form-editor-input' }}
      stylingMode='filled'
      maxLength={maxLength}
      onValueChange={onValueChange}
    >
      {icon &&
        <TextBoxButton
          name='icon'
          location='before'
          options={{
            icon: icon,
            stylingMode: 'text',
            elementAttr: { class: 'form-editor-icon' }
          }} />
      }

      <Validator>
        {required && <RequiredRule />}
        {children}
      </Validator>
    </TextBox>
  );
};