import React from 'react';
import DateBox from 'devextreme-react/date-box';
import Validator, { RequiredRule, RangeRule, CustomRule } from 'devextreme-react/validator';
import dayjs from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'

export const FormDateBox = ({
    value,
    readOnly,
    name,
    label,
    onValueChange,
    minName,
    minValue,
    required
}) => {
    const validateDate = (e, minDate) => {
        dayjs.extend(isSameOrAfter);
        var nDate = dayjs(e.value);
        var cDate = dayjs(minDate);
        var result = nDate.isSameOrAfter(cDate, 'day') // compare day
        return result;
        //return e.value >= minDate;
    }

    return (
        <DateBox
            value={value}
            readOnly={readOnly}
            name={name}
            label={label}
            inputAttr={{ class: 'form-editor-input' }}
            stylingMode='filled'
            displayFormat='shortdate'
            dateSerializationFormat='yyyy-MM-dd'
            placeholder='MM/dd/yyyy'
            useMaskBehavior={true}
            onValueChange={onValueChange}
            type='date'
        // isValid={value && value >= minValue}
        // invalidDateMessage={value ? name + 'must be equal to or greater than' + minName : name + 'is required'}
        >
            {required &&
                <Validator>
                    <RequiredRule message={label + ' is required'} />
                    {minValue && minName &&
                        <CustomRule type='custom' validationCallback={(e) => validateDate(e, minValue)} reevaluate={true} message={name + ' must be equal to or greater than ' + minName} />
                    }
                </Validator>
            }
        </DateBox>
    );
};