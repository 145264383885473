import {
    useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import GetFetch from '../hooks/GetFetch';
import CustomStore from 'devextreme/data/custom_store';
import DataGrid, {
    KeyboardNavigation,
    Pager,
    Paging,
    SearchPanel,
    Column,
    Export,
    Scrolling
} from 'devextreme-react/data-grid';
import CustomPreparedHeaderCell from '../components/CustomPreparedHeaderCell';
import { logExport, useAuth } from '../hooks/useAuth';
import ExportGrid from '../components/ExportGrid';
import { allowedPageSizes, pageFormats } from "../components/GlobalDataGridConfigurations";
import ClosingAlert from '../components/ClosingAlert';
import { BaseButton } from '../components/BaseButton/BaseButton';

const OccupancyAgreementsTable = () => {
    const navigate = useNavigate();
    const { user } = useAuth();
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState();

    const fetchOccupancyAgreements = () => {
        return GetFetch('/v1/OccupancyAgreement')
    };
// const customerDataSource = new DataSource({
//         store: {
//             type: 'array',
//             data: customers,
//             key: 'CustomerId'
//         },
//         paginate: true,
//         pageSize: 10
//     });
    const dataSource = new CustomStore({
        key: 'OccupancyAgreementId',
        paginate: true,
        pageSize: 10,
        load: async () => {
            const data = await fetchOccupancyAgreements();
            const { Message } = data;
            // console.log(data);
            let checkedData = [];
            if (Message)
                checkedData = Message.map(
                    (
                        {
                            OccupancyAgreementId,
                            BuildingByCustomerId,
                            EffectiveDate,
                            ExpirationDate,
                            CreatedDate,
                            CreatedBy,
                            UpdatedDate,
                            UpdatedBy,
                            OANumber,
                            LeaseNumber,
                            MemberName,
                            TotalRentSqFt,
                            JURentSqFt,
                            RUFactor,
                            Inactive,
                        }
                    ) => {

                        return {
                            OccupancyAgreementId: OccupancyAgreementId,
                            BuildingByCustomerId: BuildingByCustomerId,
                            EffectiveDate: EffectiveDate,
                            ExpirationDate: ExpirationDate,
                            CreatedDate: CreatedDate,
                            CreatedBy: CreatedBy,
                            UpdatedDate: UpdatedDate,
                            UpdatedBy: UpdatedBy,
                            OANumber: OANumber,
                            LeaseNumber: LeaseNumber,
                            MemberName: MemberName,
                            TotalRentSqFt: TotalRentSqFt,
                            JURentSqFt: JURentSqFt,
                            RUFactor: RUFactor,
                            Inactive: Inactive,
                            Status:  Inactive ? "Inactive" : "Active",
                        };
                    }
                );

            return checkedData;
        }
    });
    const pdfFont = () => { return 7; }
    const wrapPdfCell = () => {
        return true;
    }
    const handleExport = (e) => {
        logExport(user.email);
        ExportGrid(e, "Occupancy_Agreements_Data", wrapPdfCell(), pdfFont())
    }

    const renderEditCell = ({ data }) => {
        // console.log(data)
        return (
            <BaseButton
                variant='outlined'
                ariaLabel={`edit-button-occupancy-agreement-with-id-${data.OccupancyAgreementId}`}
                id={`edit-button-occupancy-agreement-with-id-${data.OccupancyAgreementId}`}
                label="See Details/Edit"
                noStyle={true}
                onClick={() => navigate(`${data.OccupancyAgreementId}`)}
            />
        )
    }

    return (
        <div>
            <ClosingAlert
                severity="error"
                message={errorMessage}
                visible={error}
            />

            <div>
                {
                    !error &&
                    <span
                        aria-live="polite"
                        aria-atomic="true"
                    >
                        <DataGrid
                            columnAutoWidth={true}
                            dataSource={dataSource}
                            onCellPrepared={CustomPreparedHeaderCell}
                            showBorders={true}
                            showColumnLines={true}
                            showRowLines={true}
                            rowAlternationEnabled={true}
                            onExporting={e => handleExport(e)}
                        >
                            <KeyboardNavigation enabled={true} />
                            <SearchPanel visible={true} highlightCaseSensitive={true} />
                            <Scrolling />
                            <Paging defaultPageSize={10} />
                            <Pager
                                showPageSizeSelector={true}
                                allowedPageSizes={allowedPageSizes}
                            />
                            <Column cellRender={renderEditCell} width={170}/>
                            <Column dataField="OccupancyAgreementId" visible={false} />
                            <Column dataField="BuildingByCustomerId" visible={false} />
                            <Column dataField="OANumber" caption="OA Number" />
                            <Column dataField="EffectiveDate" caption="Effective Date" />
                            <Column dataField="ExpirationDate" caption="Expiration Date" />
                            <Column dataField="LeaseNumber" caption="Lease Number" />
                            <Column dataField="MemberName" caption="Member Name"  />
                            <Column dataField="TotalRentSqFt" caption="Total Rent Sq. Ft" />
                            <Column dataField="JURentSqFt" caption="JU Rent Sq. Ft" visible={false} />
                            <Column dataField="RUFactor" caption="RU Factor" visible={false} />
                            <Column dataField="Inactive" caption="Inactive" visible={false} />
                            <Column dataField="Status" caption="Status"  />
                            <Column dataField="CreatedDate" caption="Created Date" visible={false} />
                            <Column dataField="CreatedBy" caption="Created By" visible={false}/>
                            <Column dataField="UpdatedDate" caption="Updated Date" visible={false}/>
                            <Column dataField="UpdatedBy" caption="Updated By" visible={false}/>
                            <Export
                                enabled={true}
                                formats={pageFormats}
                            />
                        </DataGrid>
                    </span>
                }
            </div>
        </div >
    );
};


export default OccupancyAgreementsTable;