import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import ForwardIcon from '@mui/icons-material/Forward';

/**
 * 
 * @module ClosingAlert
 * This is the base of an alert UI component for RMS.
 * It uses a MUI Alert as the root UI component.
 * This component is to be used to alert the user with special content by rendering a temporary message.
 * 
 */

const ClosingAlert = (
    { 
        clickableIcon,
        enableAlertIcon,
        hideAlert,  
        message, 
        severity,
        visible 
    }
) => {
    return (
        <>
            <Snackbar
                open={visible}
                // onClose={() => { hideAlert() }}
                anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
                sx={{ width: 1 }}
            >
                <Alert
                    severity={severity}
                    variant='filled'
                    sx={{
                        width: '90%',
                        backgroundColor: 
                        severity === 'error' ? '#C41230' : 
                        severity === 'success' ? '#498225' : 
                        severity === 'info' ? '#005288':
                        severity === 'warning' ? '#DD742D': undefined,
                        color: severity === 'warning' ? '#06152B' : undefined
                    }} 
                    action={
                        enableAlertIcon  && 
                        <IconButton
                            aria-label="forward"
                            color="inherit"
                            size="small"
                            onClick={clickableIcon}
                        >
                            <ForwardIcon fontSize="inherit" />
                        </IconButton>
                    }>
                        <div
                            aria-atomic="true"
                            aria-live="assertive"
                            role="alert"
                        >
                            {message}
                        </div>
                </Alert>
            </Snackbar>
        </>
    );
};

export default ClosingAlert;