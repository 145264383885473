import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ROUTE_CRMA_UPLOAD, ROUTE_WELCOME } from "../../routes/Routes";
import ClosingAlert from "../../components/ClosingAlert";
import { BaseDateField } from "../../components/BaseDateField/BaseDateField";
import { BaseButton } from "../../components/BaseButton/BaseButton";
import PutPostPatchFetch from "../../hooks/PutPostPatchFetch";
import "./CRMA_Upload.scss";
import { DateBox } from "devextreme-react";

const CRMA_UploadPage = () => {
    const { title } = ROUTE_CRMA_UPLOAD;

    const [selectedFiles, setSelectedFiles] = useState([]);
    const [_billingDate, set_BillingDate] = useState();
    
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [alertVisible, setAlertVisible] = useState(false);

    const navigate = useNavigate();
    const navigateToRoute = () => navigate(ROUTE_WELCOME.withSlash);

    useEffect(() => { document.title = title }, []);

    const handleFileUpload = (event) => {
        const files = Array.from(event.target.files);
        setSelectedFiles(prevFiles => [...prevFiles, ...files]);
    };

    const handleSubmit = async (event) => {
        // console.log("event: ", event)
        event.preventDefault();

        const formData = new FormData();

        for (let i = 0; i < selectedFiles.length; i++) { 
            formData.append("Files", selectedFiles[i])
        };
        console.log("selected files: ", selectedFiles)
        console.log("form data: ", formData)

        try {
            const { Errors, Message, Success } = 
                await PutPostPatchFetch(`/v1/CRMADownload/SaveCRMASpreadsheets/?billingDate=${_billingDate}`, 
                'POST', formData, 'multipart/form-data');

            if (Success) {
                console.log("Message: ", Message)
                setSuccessMessage('Files submitted successfully!');
                setAlertVisible(true);
            } 
            
            if (Errors) {
                console.error(Errors);
                setErrorMessage('Please select files to submit.');
                setAlertVisible(true);
            }
        } catch (error) {
            console.error('Error: ', error);
        }

    };

    const handleCloseAlert = () => {
        setAlertVisible(false);
    };
    
    const convertToMegabits = (number) => {
        if (number > 999) {
            number / 1000;
            const fixedNumber = Math.round(number / 100) / 10
            return <> {fixedNumber} MB </>;
        };
        
        return <> {number} KB </>;
    };

    return (
        <div>
            <h1> {title} </h1>

            <div className="block block__div--wrapper">
                <DateBox
                    dateSerializationFormat="MM/dd/yyyy"
                    useMaskBehavior={true}
                    displayFormat="monthAndYear"
                    inputAttr={{
                        "aria-label": "Billing Date", 
                        "aria-required": "true"
                    }}
                    label="Billing Date"
                    onValueChanged={(e) => set_BillingDate(e.value)}
                    showDropDownButton={false}
                    type="date"
                    value={_billingDate ?? null}
                    variant={"outlined"}
                />
            </div>            
            <div className="block block__div--wrapper">
                <BaseButton
                    label={"Select file"}
                    onClick={() => document.getElementById('file-upload-input').click()}
                    variant={"outlined"}
                />
                <input
                    type="file"
                    accept=".csv"
                    multiple
                    onChange={handleFileUpload}
                    style={{ display: 'none' }}
                    id="file-upload-input"
                />
                <BaseButton
                    aria-disabled={selectedFiles.length === 0}
                    disabled={selectedFiles.length === 0}
                    onClick={handleSubmit}
                    variant={"outlined"}
                    label={"Submit"}

                />
            </div>

            <div className="block">
                <div className={
                    selectedFiles.length === 0 ? 
                    "block__unscrollable-container" 
                    : 
                    "block__scrollable-container"
                    }>
                    {
                        selectedFiles.length === 0 && 
                        <div className="inline__empty-list">                            
                            <span>
                                No Files Selected
                            </span>
                        </div>
                        
                    }
                    {
                        selectedFiles.length > 0 &&
                        <ul className="block__unordered-list">
                            {
                                selectedFiles.map(({
                                    index,
                                    name, 
                                    size
                                }) => {
                                    const formattedSize = convertToMegabits(size);
                                    return (
                                        //TODO: Using name instead of index gets rid of error, but need to find a better indexing method
                                        <li key={name} className="inline__list-item">
                                            <span className="inline__span--file-name">
                                                {name} 
                                            </span>
                                            <span className="inline__span--file-size">
                                                {formattedSize}
                                            </span>
                                        </li>
                                    );
                                })
                            }
                        </ul>
                    }
                </div>
            </div>

            <div className="block block__div--wrapper">
                <BaseButton
                    label={"cancel"}
                    variant={"outlined"}
                    onClick={navigateToRoute}
                />
            </div>

            <ClosingAlert
                visible={alertVisible}
                hideAlert={handleCloseAlert}
                message={successMessage || errorMessage}
                severity={successMessage ? 'success' : 'error'}
                enableAlertIcon={true}
                clickableIcon={handleCloseAlert}
            />
        </div>
    );
};

export default CRMA_UploadPage;