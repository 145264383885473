import React from 'react';
import UpdateOccupancyAgreement from './UpdateOccupancyAgreement';

const OccupancyAgreementDetails = () => {
    
    return (
        <>
            <h4>Occupancy Agreement Details</h4>
            <UpdateOccupancyAgreement/>
        </>
    );
}
export default OccupancyAgreementDetails;
